import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthGuard } from './services/auth-guard.service';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsNavbarComponent } from './bs-navbar/bs-navbar.component';
import { HomeComponent } from './home/home.component';
// import { UserManagementComponent } from './admin/user-management/user-management.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { JwtModule } from '@auth0/angular-jwt';
import { AdminAuthGuard } from './services/admin-auth-guard.service';
import { AccessGuardGuard } from './services/access-guard.guard';
import { NoAccessComponent } from './no-access/no-access.component';
import { MaterialModule } from './material-module/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomHttpService } from './services/custom-http.service';
import { LocationStrategy, HashLocationStrategy, APP_BASE_HREF } from '../../node_modules/@angular/common';
import { VerifyuserComponent } from './web-module/components/verifyuser/verifyuser.component';
import { MyDialogComponent } from './web-module/components/my-dialog/my-dialog.component';
import { DatePipe } from '@angular/common';

export function jwtTokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    BsNavbarComponent,
    HomeComponent,    
    NoAccessComponent,
    VerifyuserComponent,
    MyDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CustomFormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    NgbModule.forRoot(),
    JwtModule.forRoot({
      config: {
        // ...
        tokenGetter: jwtTokenGetter
      }
    }),
    RouterModule.forRoot([
      // { path: '', component: HomeComponent, canActivate: [AuthGuard] },
      // { path: 'registration', component: PatientRegistrationComponent, canActivate: [AuthGuard] },
      // { path: 'analysis', component: AnalysisComponent, canActivate: [AuthGuard] },
      // { path: 'login', component: LoginComponent },

      // { path: 'admin/usemanagement', component: UserManagementComponent, canActivate: [AuthGuard, AdminAuthGuard] },
      { path: 'no-access', component: NoAccessComponent },

      {
        path: 'login',
        loadChildren: './auth-module/auth.module#AuthModule'
      },
      {
        path: 'UI',
        loadChildren: './web-module/web.module#WebModule'
      },
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'verify', component: VerifyuserComponent },
    ]),
    HttpModule,

  ],
  providers: [
    DatePipe,
    AuthGuard,
    AdminAuthGuard,
    AccessGuardGuard,
    MatDatepickerModule,
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent],
  entryComponents :[MyDialogComponent]
})
export class AppModule { }
