import { environment } from '../../environments/environment.prod';

import { Http, RequestOptions } from '@angular/http';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends DataService {
  headers: any;
  constructor(http: Http, public jwtHelper: JwtHelperService, private router: Router, private httpService: Http) {
    super(environment.apiBaseURL + 'api/Login', http, null);

    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: this.getBearerToken()
    });

  }



  login(credentials) {
    return this.post(credentials);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  isLoggedIn() {
    if (localStorage.getItem('token') != null) {
      return !this.jwtHelper.isTokenExpired();
    }
    else if (localStorage.getItem('token') == null) {
      return false;
    }
    else
      return true;

  }

  getBearerToken() {
    return 'Bearer ' + localStorage.getItem('token');
  }

  get currentUser() {
    const token = this.jwtHelper.tokenGetter();

    if (!token) { return null; }

    return this.jwtHelper.decodeToken(token);
  }

  Verify(key, dob) {
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Methods' : 'GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS'
    });
    const options = new RequestOptions({ headers: this.headers });
    console.log(this.headers,'this.headers')
    
    return this.httpService.put(environment.apiBaseURL + 'api/Login/Verify/' + key + '/' + dob, null, options)
      .pipe(map(response => response.json()), catchError(this.handleError));
  }

  verifyUser(username, password) {
    const options = new RequestOptions({ headers: this.headers });
    return this.httpService.post(environment.apiBaseURL + 'api/Login/VerifyPassword', { UserName: username, Password: password, IsDoctor: 1 },
      options)
      .pipe(map(response => response.json()), catchError(this.handleError));
  }




}
