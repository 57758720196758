// import { HttpHeaders } from '@angular/common/http';
import { AppError } from '../common/app-error';
import { NotFoundError } from '../common/not-found-error';
import { BadRequestError } from '../common/bad-request-error';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
// import { Http, RequestOptions } from '@angular/http';
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { throwError } from 'rxjs';


export class DataService {


  constructor(private url: string, private http: Http, public headers?: Headers) { }

  getAll() {
    const options = new RequestOptions({ headers: this.headers });
    return this.http.get(this.url, options).pipe(map(response => response.json()), catchError(this.handleError));
  }

  get(id) {
    const options = new RequestOptions({ headers: this.headers });
    return this.http.get(this.url + '/' + id, options)
      .pipe(map(response => response.json()), catchError(this.handleError));
  }



  post(resource) {
    // const _headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    const options = new RequestOptions({ headers: this.headers });
    
    return this.http.post(this.url, resource, options)
      .pipe(map(response => response.json()), catchError(this.handleError));
  }

  update(id, resource) {
    const options = new RequestOptions({ headers: this.headers });
    console.log(JSON.stringify(resource));
    return this.http.put(this.url + '/' + id, resource,options)
      .pipe(map(response => response.json()), catchError(this.handleError));
  }

  delete(id) {
    return this.http.delete(this.url).pipe(map(response => response.json()), catchError(this.handleError));
  }

  public handleError(error: Response) {
    if (error.status === 400) {
      return throwError(new BadRequestError(error));
    }
    if(error.status===401){      
     // alert("Incorrect Login Credential");
      return  throwError("UnAuthorised");
    } 
    if (error.status === 404) {
      return throwError(new NotFoundError(error));
    }

    return throwError(new AppError(error));
  }

}
