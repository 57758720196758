import { CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

  constructor(private authService:AuthService,private router:Router) { }
  canActivate(route,state:RouterStateSnapshot){

    let user = this.authService.currentUser;
    if(user && user.Role.indexOf('Admin')!=-1) return true;
      
    this.router.navigate(['/no-access']);
    return false;
  }

}
