import { AuthService } from './auth.service';
import { EpilepsyType } from '../models/EpilepsyType';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { BadRequestError } from '../common/bad-request-error';
import { NotFoundError } from '../common/not-found-error';
import { AppError } from '../common/app-error';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CustomHttpService {
    headers: any;
    headersoctet: any;
    constructor(private http: Http, private authService: AuthService) {

        // this.authToken = authService.getBearerToken();

        this.headers = new Headers({
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: authService.getBearerToken()
        });
        this.headersoctet = new Headers({
            'Content-Type': 'application/octet-stream;',
            Authorization: authService.getBearerToken()
        });
    }

    getLatestRegistration(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/GetLatestRegistration', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }

    getRegistrationHistory(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/GetRegistrationHistory', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }
    getRegistrationReport(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/GetRegistrationReport', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }
    getPreviousRegistrationReport(resource) {
        const options = new RequestOptions({ headers: this.headers });
        return this.http.post(environment.apiBaseURL + 'api/Registration/GetPreviousRegistrationReport', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }
    downloadRegistrationReport(passportid) {
        const options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: this.headersoctet });
        return this.http.get(environment.apiBaseURL + 'api/Registration/DownloadRegistrationReport/' + passportid, options)
            .pipe(map((res) => {
                var blob = new Blob([res.blob()], { type: 'application/pdf' })
                return blob;
            }), catchError(this.handleError));
    }


    sendRegistrationReport(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/SendRegistrationReport', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }
    getRegistrationReportComparison(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/GetRegistrationReportComparison', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }

    getSeizureType(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/GetSeizureType', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }
    getSeizureSyndrome(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/GetSeizureSyndrome', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }
    getEpilepsyCause(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/GetEpilepsyCause', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }

    getDataAnalysisRecord(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.post(environment.apiBaseURL + 'api/Registration/GetDataAnalysisRecord', resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }

    getOldRegistration(id) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.get(environment.apiBaseURL + 'api/Registration/GetComparison/' + id, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }

    chkPasswordValidity(user) {
        const options = new RequestOptions({ headers: this.headers });
        return this.http.get(environment.apiBaseURL + 'api/Login/ChkPasswordValidity/' + user, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }

    changePassword(credentials, username) {
        const options = new RequestOptions({ headers: this.headers });
        return this.http.post(environment.apiBaseURL + 'api/Login/ResetPasswordDoctor', { UserName: username, OldPassword: credentials.OldPassword, NewPassword: credentials.NewPassword },
            options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }

    verifyUser(username, password) {
        const options = new RequestOptions({ headers: this.headers });
        return this.http.post(environment.apiBaseURL + 'api/Login/VerifyPassword', { UserName: username, Password: password, IsDoctor: 1 },
            options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }

    //EVENT
    getLatestEvents(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.get(environment.apiBaseURL + 'api/Diary/EventsDoctor/' + resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }
    getLatestEventsDetailsByID(resource) {
        const options = new RequestOptions({ headers: this.headers });

        return this.http.get(environment.apiBaseURL + 'api/Diary/EventsDoctorById/' + resource, options)
            .pipe(map(response => response.json()), catchError(this.handleError));
    }
    //EVENT

    private handleError(error: Response) {
        if (error.status === 400) {
            return throwError(new BadRequestError(error));
        }
        if (error.status === 404) {
            return throwError(new NotFoundError(error));
        }

        return throwError(new AppError(error));
    }
}
