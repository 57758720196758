import { AuthService } from '../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bs-navbar',
  templateUrl: './bs-navbar.component.html',
  styleUrls: ['./bs-navbar.component.css']
})
export class BsNavbarComponent implements OnInit {
  router: any;

  constructor(public authService:AuthService) { }

  ngOnInit() {   
    this.checkLogin();
  }

  collapsed = true;
     toggleCollapsed(): void {
       this.collapsed = !this.collapsed;
     }

     checkLogin() {      
      if(this.authService.isLoggedIn())
      {
        //console.log(this.router.url);
        //this.router.navigate(['/login']);
      }
  }

}
