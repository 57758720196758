import { CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { isString } from 'util';
@Injectable({
  providedIn: 'root'
})
export class AccessGuardGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  canActivate(route, state: RouterStateSnapshot) {

    let user = this.authService.currentUser;
    if (user && user.Role.indexOf('Admin') != -1) {
      return true;
    }

    // console.log('string user => ', user.Role)
    // console.log('state => ', state.url)

    if (user.Role.indexOf("Edit & View") != -1) {
      if (state.url.includes("/UI/home/registration/") || state.url.includes("/UI/home/report/")) {
        return true;
      }
    }
    if (user.Role.indexOf("Contributor") != -1) {
      if (state.url.includes("/UI/home/registration")) {
        return true;
      }
    }
    if (user.Role.indexOf("Patient Registration") != -1) {
      if (state.url.includes("/UI/home/registration")) {
        return true;
      }
    }
    if (user.Role.indexOf("View") != -1) {
      if (state.url.includes("/UI/home/report/")) {
        return true;
      }
    }
    // console.log("************************ No Access ********************");
    this.router.navigate(['/no-access']);
    return false;
  }
}
