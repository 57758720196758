import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CustomHttpService} from './custom-http.service' 

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService:AuthService,private router:Router,private httpser:CustomHttpService) { }

  canActivate(route,state:RouterStateSnapshot){
    if(this.authService.isLoggedIn())
    {
      this.httpser.chkPasswordValidity(this.authService.currentUser.UserId).subscribe(result => {
        if(result.message=='ChangePassword')
        this.router.navigate(['/UI/home/changepassword']);
      });
      return true;
    }
      
    this.router.navigate(['/login'],{queryParams:{returnUrl:state.url}});
    return false;
  }
}
