import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/date.adapter';

@Component({
  selector: 'app-verifyuser',
  templateUrl: './verifyuser.component.html',
  styleUrls: ['./verifyuser.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class VerifyuserComponent implements OnInit {
  VerifyForm;
 
  paramsServer =
  {
    searchText: '',
    pageIndex: 0,
    pageSize: 1000,
    pageCount: 0,
  };

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute,
    private fb: FormBuilder,public datePipe:DatePipe) { 
    
    this.VerifyForm = this.fb.group({
      dob: ['']
    });
  }

  ngOnInit() {
  }

  public onDoneClick() {
    let formVal = this.VerifyForm.value;

    var x = new Date(formVal.dob)
    var y = this.datePipe.transform(x, 'yyyy-MM-dd');
    formVal.dob = y

    this.authService.Verify(this.route.snapshot.queryParamMap.get("code"),formVal.dob).subscribe(response => {      
    alert(response.message);
    if(response.message=="Activated")
      this.router.navigate(['login']);
    });  
  }


}
