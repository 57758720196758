export const environment = {
  production: true,
  //apiBaseURL:'http://epilepsypassportwebapi-dev.us-west-1.elasticbeanstalk.com/'
  //apiBaseURL:'https://localhost:5001/'
  apiBaseURL: 'https://myepilepsyrecord.com/EpilepsyAPI/'
  // apiBaseURL: 'http://epilepsy.eshci.com/EpilepsyAPI/'
  // apiBaseURL: 'https://epilepsyapi.eshci.com/'
  //apiBaseURL: 'http://localhost:57456/'
  //apiBaseURL:'http://epilepsywebapi.eshci.com/'
};
