import { Component, OnInit, Inject  } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-my-dialog',
  templateUrl: './my-dialog.component.html',
  styleUrls: ['./my-dialog.component.css']
})
export class MyDialogComponent implements OnInit {

  public title:string;
  public response_msg:string;
  constructor(public thisDialogRef: MatDialogRef<MyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:ConfirmDialogModel) { 
    this.title=data.title;
    this.response_msg=data.response_msg;
  }

  ngOnInit() {
  }

  //public title:Object;
  //public response_msg:Object;
  onCloseConfirm() {
    this.thisDialogRef.close('Confirm');
  }

  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }

}

export class ConfirmDialogModel {
 
  constructor(public title: string, public response_msg: string) {
  }
}
